import * as React from 'react';
// import { Helmet } from 'react-helmet';
// import { Link, graphql } from 'gatsby';
import Layout from '../components/Layout';

class TagRoute extends React.Component {
  render() {
    // const posts = this.props.data.allMarkdownRemark.edges;
    // const postLinks = posts.map((post) => (
    // <li key={post.node.fields.slug}>
    /*{
      <Link to={post.node.fields.slug}>
    }*/
    /*{
      <h3 className="is-size-2">{post.node.frontmatter.title}</h3>
    }*/
    /*{
      </Link>
    }*/
    // </li>
    // ));
    // const tag = this.props.pageContext.tag;
    // const title = this.props.data.site.siteMetadata.title;
    // const totalCount = this.props.data.allMarkdownRemark.totalCount;
    // const tagHeader = `${totalCount} post${
    //   totalCount === 1 ? '' : 's'
    // } tagged with “${tag}”`;

    return (
      <Layout>
        <section className="section">
          {/* <Helmet title={`${tag} | ${title}`} />

          <h2 className="title">{tag}</h2>
          <p className="title">{totalCount} Projects</p>
          <ul className="taglist">{postLinks}</ul>
          <p>
            <Link to="/tags/">Browse all tags</Link>
          </p> */}
        </section>
      </Layout>
    );
  }
}

export default TagRoute;

// export const tagPageQuery = graphql`
//   query TagPage($tag: String) {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//     allMarkdownRemark(
//       limit: 1000
//       sort: { fields: [frontmatter___date], order: DESC }
//       filter: { frontmatter: { tags: { in: [$tag] } } }
//     ) {
//       totalCount
//       edges {
//         node {
//           fields {
//             slug
//           }
//           frontmatter {
//             title
//           }
//         }
//       }
//     }
//   }
// `;
